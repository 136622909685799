import styles from "./shop.module.scss"
const Shop = () => {
    return (
        <div className={styles["shop-container"]}>
            <h1>Coming Soon...</h1>
        </div>
    )
}

export default Shop
